import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import CustomInput from "../FooterPages/CustomInput";
import UserImage from "../common/UserImage";
import instance from "../common/Api";

const Profile = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState("./user.png");
  const [login_by, setLogin_by] = useState("");
  const [newFullName, setNewFullName] = useState("");

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newConfPass, setNewConPass] = useState("");

  const [userId, setUserId] = useState(null);
  const handleImageChange = (newImage) => {
    setImageSrc(newImage);
  };

  useEffect(() => {
    if (props.userData != null) {
      setUserId(props.userData.id);
      setImageSrc(props.userData.picture);
      setLogin_by(props.userData.login_by);
    }
  }, [props]);

  const handleChangePass = () => {
    if (oldPass !== "" && newPass !== "" && newConfPass !== "") {
      if (oldPass !== newPass && oldPass !== newConfPass) {
        if (newPass === newConfPass) {
          setLoading(true);
          instance
            .post(
              `/userApi/reset_password_web?mobileNumber=${props.userData.contact}&password=${newPass}&password_confirmation=${newConfPass}`
            )
            .then((response) => {
              let res = response;
              if (res.status) {
                alert(res.data.message);
                // window.location.href = "/";
              }
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            })
            .finally(() => {
              setLoading(false);
            });
          // userApi/reset_password_web?mobileNumber=9191919191&password=123456&password_confirmation=1234567
        } else {
          alert("The new password and password confirmation must match.");
        }
      } else {
        alert("New password must be not same old password");
      }
    } else {
      alert("All fields are required!");
    }
  };

  const handleChangeFullName = () => {
    if (newFullName !== "") {
      setLoading(true);
      instance
        .post(`/user/updateProfileWeb?id=${userId}&fullName=${newFullName}`)
        .then((response) => {
          let res = response;
          if (res.status) {
            alert(res.data.message);
            // window.location.href = "/";
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      alert("All fields are required!");
    }
  };

  return (
    <div
      className="Profile"
      style={{
        width: "100%",
        minHeight: "51rem",
        position: "relative",
        backgroundImage: `url(./main-bg-landscap.jpg)`,
        backgroundSize: "cover",

        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="upper">
        <div className="centeredImage">
          {/* Add your image source here */}
          <UserImage src={imageSrc} onChange={handleImageChange} />
          {/* <img
            src="./user.png"
            alt="Centered Image"
            className="circularImage"
          />
          <Button
            style={{
              marginTop: "10px",
              backgroundColor: "#58b7aa",
              borderWidth: "0",
            }}
            onClick={() => console.log("Button Clicked")}
          >
            Change
          </Button> */}
        </div>
      </div>
      <hr className="line" />
      <div className="lower">
        <div className="subPortion leftPortion">
          <h3 style={{ color: "#008dc4" }}> GENERAL INFORMATION</h3>
          {loading && (
            <div className="loading-overlay">
              <Spinner animation="border" />
            </div>
          )}
          <Row className="align-items-center">
            <Col>
              <p
                className="inline-element text-black"
                style={{
                  // marginBottom: "50px",
                  textAlign: "end",
                }}
              >
                FULL NAME{" "}
              </p>
            </Col>
            <Col>
              {props.userData && (
                <CustomInput
                  placeholder={
                    props.userData != null
                      ? props.userData.fullName
                      : "Enter Full Name"
                  }
                  // error={"this field is required"}
                  type={"text"}
                  onValueChange={(value) => setNewFullName(value)}
                />
              )}
            </Col>
          </Row>
          {/* <Row className="align-items-center">
            <Col>
              <p
                className="inline-element"
                style={{ marginBottom: "50px", textAlign: "end" }}
              >
                EMAIL ADDRESS{" "}
              </p>
            </Col>
            <Col>
              {props.userData && (
                <CustomInput
                  placeholder={props.userData.email}
                  error={"this field is required"}
                  type={"text"}
                />
              )}
            </Col>
          </Row> */}
          {props.userData && (
            <Row className="align-items-center">
              {login_by === "google" ? (
                <>
                  <Col>
                    <p
                      className="inline-element text-black"
                      style={{ marginBottom: "50px", textAlign: "end" }}
                    >
                      EMAIL ADDRESS{" "}
                    </p>
                  </Col>
                  <Col>
                    <CustomInput
                      placeholder={props.userData.email}
                      error={login_by === "google" ? "Cannot be change" : ""}
                      type={"text"}
                      disable={true}
                    />
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <p
                      className="inline-element text-black"
                      style={{ marginBottom: "50px", textAlign: "end" }}
                    >
                      MOBILE NUMBER{" "}
                    </p>
                  </Col>
                  <Col>
                    <CustomInput
                      placeholder={props.userData.contact}
                      // error={login_by === "google" ? "Cannot be change" : ""}
                      type={"text"}
                      disable={true}
                    />
                  </Col>
                </>
              )}
            </Row>
          )}
          <Button
            style={{
              marginTop: "10px",
              backgroundColor: "#007cc2",
              borderWidth: "0",
            }}
            onClick={() => handleChangeFullName()}
          >
            Save Changes
          </Button>
        </div>
        <hr className="line-lower" />
        <div className="subPortion rightPortion">
          <h3 style={{ color: "#008dc4" }}>CHANGE PASSWORD</h3>
          {login_by === "google" ? (
            <>
              <h6 style={{ color: "#ff8d72", paddingTop: "50px" }}>
                You have registered using your GOOGLE Social Account, <br />
                you can't set or change password for social accounts.
              </h6>
            </>
          ) : (
            <>
              <CustomInput
                label="Old Password"
                placeholder=""
                // error="This field is required"
                type={"text"}
                onValueChange={(value) => setOldPass(value)}
              />
              <CustomInput
                label="New Password"
                placeholder=""
                // error="This field is required"
                type={"text"}
                onValueChange={(value) => setNewPass(value)}
              />
              <CustomInput
                label="Confirm Password"
                placeholder=""
                // error="This field is required"
                type={"text"}
                onValueChange={(value) => setNewConPass(value)}
              />
              <Button
                style={{
                  marginTop: "10px",
                  backgroundColor: "#007cc2",
                  borderWidth: "0",
                }}
                onClick={() => handleChangePass()}
              >
                Update Password
              </Button>
            </>
          )}
          {/* Content for the right portion */}
        </div>
      </div>
    </div>
  );
};

export default Profile;
